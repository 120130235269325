.followPopup {
  table {
    margin: auto auto;
    width: 30%;
    text-align: center;
    vertical-align: center;
    td {
      border-bottom: 1px solid #444;
    }
  }
  button {
    font-size: 1.5rem;
  }
}

.followBtn :hover {
  filter: brightness(120%);
  -webkit-filter: brightness(120%);
}
