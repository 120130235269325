@import "./variables.scss";

.navbar {
  text-decoration: none;
  overflow: hidden;
  margin-bottom: 0;
  min-height: 60px;
  border: 0;
}

ul,
li {
  display: inline;
  padding: 0 1.5em 0 1.5em;
}

.navbarBg {
  position: absolute;
  background-color: $navBgColor;
  width: 100%;
  height: 60px;
  z-index: -1;
}

.links {
  display: table;
  margin: 0 auto;
  padding-top: 14px;
}

.text {
  margin-top: -10px;
  padding-bottom: 4px;
}

.sm {
  padding: 0 0.3em 0.3em 0.3em;

  img {
    width: 35px;
    height: 35px;
    filter: brightness(100) grayscale(100%);
    -webkit-filter: brightness(100) grayscale(100%);

    &:hover {
      $b: 100%;
      $s: 1;
      $h: 0;
      filter: brightness($b) grayscale(0) saturate($s) hue-rotate($h);
      -webkit-filter: brightness($b) grayscale(0) saturate($s) hue-rotate($h);
    }
  }
}

.br {
  padding-left: 0.8em;
}

.icon {
  display: none !important;
  font-size: 1.5rem;
  padding: 0;
}
.mobileNavbar {
  display: none;
}

@media screen and (max-width: 1382px) {
  li {
    font-size: 1.1em;
    font-family: Leon, sans-serif;
    display: block;
    padding: 1rem 0;
  }
  .br {
    display: none;
  }
  .links {
    margin-top: -20px;
    margin-left: 20px;
  }
  .icon {
    position: absolute;
    top: 14px;
    right: 22px;
    display: block !important;
  }
  .navbar {
    display: none;
  }
  .mobileNavbar {
    display: block;
    text-decoration: none;
    overflow: hidden;
    margin-bottom: 0;
    min-height: 60px;
    border: 0;
    background-color: #292929;
    height: calc(100% - 50px);
    a {
      float: none;
      padding: 0 1rem;
      display: block;
    }
  }
  .sm a:after {
    white-space: pre;
    content: "  -  " attr(title);
  }
  .banner {
    background: none;
    height: 18vh;
  }
  .logo {
    background: #1c1c1c;
  }
  .logo img {
    max-width: 80%;
  }
}

.banner {
  position: relative;
  background: url("/assets/images/background.jpg") black no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 35vh;
}

.header {
  a {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.4em;
    color: white !important;
    &.active,
    &:active,
    &:hover,
    &.focus {
      color: $linkColor !important;
      background-color: transparent;
    }
  }

  ul {
    list-style-type: none;
    font-size: 1em;
    margin-top: -2px;
  }
}

.logo {
  display: inline-block;
  text-align: center;
  vertical-align: center;
  width: 100%;
  height: 100%;

  img {
    max-height: 20vh;
    max-width: 100%;
    vertical-align: middle;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
