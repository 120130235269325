@import "./variables.scss";

.kraedt {
  background-color: #111;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.body-kraedt {
  a {
    color: $linkColor;
    &:visited {
      color: $linkColor;
    }
    &:hover,
    &.focus {
      color: $linkHoverColor;
    }
  }
  @mixin icon-button {
    background: none;
    border: none;
    color: $linkColor;
    &:hover {
      color: $linkHoverColor;
    }
  }
  .icon-button {
    @include icon-button();
  }
  .corner-controls button {
    @include icon-button();
  }
  .btn-download {
    background: $linkColor;
    &:hover {
      background: $linkHoverColor;
    }
    color: white !important;
  }
}
