@import "../styles/variables";

.panel {
  width: 20%;
  position: fixed;
  right: 10px;
  bottom: 60px;

  .item {
    margin: 10px 0;
    padding: 8px 5px;
    background: $darkText;
    border-bottom: 1px solid $primary;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  }
  .error {
    background: $negative-panel;
  }
}
